<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">{{className}}</div>
        <el-button type="primary" @click="addDrug" class="cardTitleBtn">添加药品</el-button>
      </el-col>
      <el-col :span="24">
        <el-table :data="drugList" style="width: 100%">
            <el-table-column prop="name" label="名称" sortable></el-table-column>
            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button @click="editDrug(scope.row)" type="primary" size="mini">编辑</el-button>
                    <el-button @click="onDelete(scope.row)" type="danger" size="mini">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog title="药品信息" width="60%" :visible.sync="drugTypeVisible" >
        <el-form label-width="100px">
          <el-form-item label="文章封面" prop="coverImageUrl">
            <el-row>
                <el-col :span="4">
                  <img style="width: 200px; height: 200px" :src="drugForm.coverImageUrl"/>
                </el-col>
                <el-col :span="2" :offset="3">
                  <el-upload :http-request="imgoUpload" action="" :show-file-list="false">
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="名称">
              <el-input v-model="drugForm.name" placeholder="请填写药品名称"></el-input>
          </el-form-item>
          <el-form-item label="适应症">
              <el-input type="textarea" v-model="drugForm.indication" placeholder="请填写药品适应症"></el-input>
          </el-form-item>
          <el-form-item label="不良反应">
              <el-input type="textarea" v-model="drugForm.adverseReaction" placeholder="请填写药品不良反应"></el-input>
          </el-form-item>
          <el-form-item label="注意事项">
              <el-input type="textarea" v-model="drugForm.note" placeholder="请填写药品注意事项"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="drugTypeVisible = false">取 消</el-button>
            <el-button type="primary" @click="addAndEditDrug">确 定</el-button>
        </div>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      className: '',
      drugList:[],
      classForm: {
        guid: '',
        name: ''
      },
      drugForm: {
        adverseReaction: "",
        categoryGuid: "",
        coverImageUrl: "",
        createBy: "",
        createTime: "",
        guid: "",
        indication: "",
        name: "",
        note: "",
        updateBy: "",
        updateTime: ""
      },
      drugTypeVisible: false
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.className = self.$route.query.name
    self.getDrugClassDetail(self.$route.query.guid || null)
  },
  methods: {
    // 获取分类下药品列表
    getDrugClassDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/api/medicine/list?categoryGuid="+guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function(response) {
            self.drugList = response.data.data
          })
          .catch(function(error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      }
    },
    // 添加药品
    addDrug() {
      let self = this
      self.drugForm = {
        adverseReaction: "",
        categoryGuid: "",
        coverImageUrl: "",
        createBy: "",
        createTime: "",
        guid: "",
        indication: "",
        name: "",
        note: "",
        updateBy: "",
        updateTime: ""
      }
      self.drugTypeVisible = true
    },
    // 修改药品
    editDrug(row) {
      let self = this
      self.drugForm = row
      self.drugTypeVisible = true
    },
    // 确定添加或修改药品
    addAndEditDrug() {
      let self = this
      console.log(self.drugForm);
      self.drugForm.categoryGuid = self.$route.query.guid
      self.$axios.post('/api/medicine/saveOrUpdate',self.drugForm,{
        headers: { token: self.userInfo.token }
      })
      .then(function() {
        self.$message({
          type: 'success',
          message: '添加成功'
        })
        self.getDrugClassDetail(self.$route.query.guid || null)
        self.drugTypeVisible = false
      })
      .catch(function(err) {
        self.$message({
            message: "添加失败："+err.response.data.message,
            type: "warning",
          });
      })
    },
    // 删除
    onDelete(row) {
      let self = this;
      self
        .$confirm("确定删除 " + (row.name || "未命名") + "？")
        .then(() => {
          self.$axios
            .delete("/api/medicine/delete", {
              data: { guid: row.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.getDrugClassDetail(self.$route.query.guid || null)
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    // 上传图片
    imgoUpload(files) {
        let self = this;
        let img = new FormData()
        img.append('imageArray', files.file)
        self.$axios
          .post("/api/common/image/upload/", img ,{
          headers: { token: self.userInfo.token,'Content-Type':'multipart/form-data'}
          })
          .then(function(res) {
              self.drugForm.coverImageUrl = res.data.data[0]
          })
          .catch(function (error) {
              self.$message({
                  message:error.response.data.message,
                  type: "warning",
              });
          });
    }
  },
};
</script>
